body{
      font-family: 'Architects Daughter', cursive;
      font-size: 19px;
}

.grilla-subtitulo {
  display: grid;
  margin-top: 25px;
  grid-template-areas:
    "subt-con-grill accion";
}

.subtitulo-con-grilla{
  grid-area: subt-con-grill;
  margin-left: 15px;
}


.grid-boton-accion{
  grid-area: accion;
  align-content: center;
  margin-top: 10px;
  margin-right: 30px;
  margin-left: auto;

}


.boton-transparente {
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  overflow: hidden;
}
.boton-transparente:hover {
  background: #0090FF;
  color: black;
}
.boton-transparente:disabled {
  opacity: 0.3;
}


.editarComponente {
  font-family: 'Architects Daughter', cursive;
  font-size: 20px;
  padding-left: 5px;
  margin-left: 5px;
  color: black;
  border-radius: 6px;
}




.subtitulo{
  margin-left: 15px;
  padding-top: 10px;
}

.info-general{
  max-width: 1200px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px;
  background: rgba(76,164,247,0.15)
}

.observaciones{
  max-width: 1200px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px;
  background-color: rgba(247,143,245,0.45);
}

.turnos-recientes{
  max-width: 1200px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px;
  background: rgba(150,255,173,0.42);
}

.historico-turnos{
  max-width: 1200px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px;
  background-color: rgba(249,169,169,0.1);
}

.proximos-turnos{
  max-width: 1200px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 10px;
  background-color: rgba(249,202,127,0.42);
}

.link-regreso{
  margin-top: 35px;
}
