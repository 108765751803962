  .barraBusqueda {
    font-family: 'Architects Daughter', cursive;
    font-size: 20px;
    color: black;
    text-align: center;
    border-radius: 16px;
    font-size: 19px;
    padding: 10px 20px 10px 20px;
    box-sizing: border-box;
    margin-bottom: 30px;
    margin-left: 10px;
    margin-top: 5px;
  }
