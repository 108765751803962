.boton-cancelar-paciente{
  margin-left: 30px;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  overflow: hidden;

}
.boton-cancelar-paciente:hover {
  background: #0090FF;
  color: black;
}

