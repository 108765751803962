.boton {
  font-family: 'Architects Daughter', cursive;
  font-size: 20px;
  color: white;
  text-align: center;
  border-radius: 16px;
  font-size: 21px;
  background: mediumblue;
  padding: 10px 20px 10px 20px;
  border: none;
  margin-bottom: 20px;
}
.boton:hover {
  background: #0090FF;
  color: black;
}

