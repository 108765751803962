.boton-turno {
  font-family: 'Architects Daughter', cursive;
  color: white;
  text-align: center;
  border-radius: 22px;
  font-size: 21px;
  background: mediumblue;
  padding: 10px 20px 10px 20px;
  border: none;
  width: 100%;
  height: 100%;
}
.boton-turno:hover {
  background: #0090FF;
    cursor: pointer;
}

.boton-rojo {
  margin-top: 35px;
  font-family: 'Architects Daughter', cursive;
  color: white;
  text-align: center;
  border-radius: 22px;
  font-size: 21px;
  background: red;
  padding: 10px 20px 10px 20px;
  border: none;
  width: 100%;
  height: 100%;
}
.boton-rojo:hover {
background: #0090FF;
    cursor: pointer;
}
.boton-verde {
  margin-top: 50px;
  font-family: 'Architects Daughter', cursive;
  color: white;
  text-align: center;
  border-radius: 22px;
  font-size: 21px;
  background: green;
  padding: 10px 20px 10px 20px;
  border: none;
  width: 100%;
  height: 100%;
}
.boton-verde:hover {
background: #0090FF;
    cursor: pointer;
}



.modulo-turnos {
  max-width: 900px;
}

.barra-selectora-paciente{
  margin-left: 25px;
}

.botones-turnos {
  margin-top: 30px;
  margin-left: 35px;
}

.buton-radial{
  margin-top: 15px;
}


.boton-transparente-turno {
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  overflow: hidden;
  font-family: 'Architects Daughter', cursive;
  font-size: 19px;
}
.boton-transparente-turno:hover {
  background: #0090FF;
  cursor: pointer;
}


.grilla-turnos {
  display: grid;
  grid-template-columns: 0.4fr 0.3fr 0.3fr;
  grid-gap: 10px;
  max-width: 1200px;
  margin-left: 20px;

}

.grilla-turnos-titulo-box-1{
   grid-column: 2;
   grid-row: 1;
   min-height: 55px;
   font-weight: bold;
   font-size: larger;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #dfdfdf;
   background-color: rgb(83, 255, 26, 0.45);
   border-radius: 10px;
}
.grilla-turnos-titulo-box-2{
   grid-column: 3;
   grid-row: 1;
   min-height: 55px;
   font-weight: bold;
   font-size: larger;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #dfdfdf;
   background-color: rgb(83, 255, 26, 0.45);
   border-radius: 10px;
}
.grilla-turnos-titulo-horario{
   grid-column: 1;
   grid-row: 1;
   min-height: 55px;
   font-weight: bold;
   font-size: larger;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #dfdfdf;
   background-color: rgb(83, 255, 26, 0.45);
   border-radius: 10px;
}


.grilla-turnos-horario{
   grid-column: 1;
   font-weight: bold;
   display: flex;
   justify-content: center;
   align-items: center;
}
.grilla-turnos-horario:nth-child(even) {
  background-color: rgb(102, 102, 153, 0.1);
}
.grilla-turnos-horario:nth-child(odd) {
  background-color: rgb(153, 51, 153, 0.3);
}

.react-calendar button {
    font-size: x-large;
}

.react-calendar {
    margin-left: 25px;
    font-size: x-large;
    text-align: center;
    max-width: 600px;
}

.react-calendar__tile--now {
  background: rgb(0, 0, 255, 0.5);
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-family: 'Architects Daughter', cursive;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  font-family: 'Architects Daughter', cursive;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
  background-color: green;
}

.titulares{
  margin-left: 15px;

}

