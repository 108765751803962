
.grilla-observacion {
  display: grid;
  margin-top: 25px;
  border-bottom: 1px solid;
  margin-right: 30px;
  border-bottom-color: slategray;
  grid-template-areas:
    "data-obs boton-borrar";
}

.data-obs{
  grid-area: data-obs;
  margin-left: 15px;
}

.boton-borrar-obs{
  grid-area: boton-borrar;
  align-content: center;
  margin-top: 10px;
  margin-right: 30px;
  margin-left: auto;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  overflow: hidden;
}
.boton-borrar-obs:hover {
  background: #0090FF;
  color: black;
}


.grilla-observacion-2 {
  display: grid;
  margin-top: 25px;
  border-bottom: 1px solid;
  margin-right: 30px;
  border-bottom-color: slategray;
  grid-template-areas:
    "data-obs boton-guardar boton-cancelar";
}


.boton-guardar-obs{
  grid-area: boton-guardar;
  align-content: center;
  margin-top: 10px;
  margin-right: 30px;
  margin-left: auto;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  overflow: hidden;
}
.boton-guardar-obs:hover {
  background: #0090FF;
  color: black;
}

.boton-cancelar-obs{
  grid-area: boton-cancelar;
  align-content: center;
  margin-top: 10px;
  margin-right: 30px;
  margin-left: auto;
  border: none;
  background-color: transparent;
  background-repeat: no-repeat;
  outline: none;
  overflow: hidden;
}
.boton-cancelar-obs:hover {
  background: #0090FF;
  color: black;
}



.editarComponente {
  font-family: 'Architects Daughter', cursive;
  font-size: 20px;
  padding-left: 5px;
  margin-left: 5px;
  color: black;
  border-radius: 6px;
}
