html *
{
   font-size: xx-large;
  font-family: 'Open Sans', sans-serif !important;
}

.grilla {
  display: grid;
  grid-template-areas:
    "titulo titulo titulo titulo"
    "panel1 panel2 panel3 panel4";
  background: url(./kine.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 1300px;
  background-size: cover;
}

.titulo-general{
  grid-area: titulo;
  min-height: 350px;
  display: grid;
  align-content: center;
  margin: auto;
  font-size: xx-large;
   color: blue;
}

.opcion1{
  grid-area: panel1;
  min-height: 200px;
  display: grid;
  align-content: center;
  margin: auto;
}

.opcion2{
  grid-area: panel2;
  display: grid;
  align-content: center;
  margin: auto;
}
.opcion3{
  grid-area: panel3;
  display: grid;
  align-content: center;
  margin: auto;
}
.opcion4{
  grid-area: panel4;
  display: grid;
  align-content: center;
  margin: auto;
}


.boton {
  font-family: 'Architects Daughter', cursive;
  font-size: 20px;
  color: white;
  text-align: center;
  border-radius: 16px;
  font-size: 21px;
  background: mediumblue;
  padding: 10px 20px 10px 20px;
  border: none;
}
.boton:hover {
  background: #0090FF;
  color: black;
}


